@media all and (min-width: 1220px), print {
    .offset {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .offset-top {
        padding-top: 35px;
    }

    .offset-bottom {
        padding-bottom: 35px;
    }

    .offset-top-single {
        padding-top: 25px;
    }

    .offset-bottom-single {
        padding-bottom: 25px;
    }

    .offset-top-none {
        padding-top: 0;
    }

    .offset-bottom-none {
        padding-bottom: 0;
    }

    h1,
    .headline-h1 {
        font-size: 4.8rem;
        line-height: 1.5;
        text-transform: none;
        margin-bottom: 0;
    }

    h2,
    .headline-h2 {
        font-size: 3.6rem;
        line-height: 1.5;
        text-transform: none;
        margin-bottom: 0;
    }

    h3,
    .headline-h3 {
        font-size: 3rem;
        line-height: 1.5;
        text-transform: none;
        margin-bottom: 0;
    }

    h4,
    .headline-h4,
    h5,
    .headline-h5,
    h6,
    .headline-h6 {
        font-size: 2.2rem;
        line-height: 1.5;
        text-transform: none;
        margin-bottom: 0;
    }

    body {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    body p a {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    body .content-container ul:not(.contact-list) li a,
    body .media-container-element ul:not(.contact-list) li a,
    body .section-content-element ul:not(.contact-list) li a,
    body .content-container ol li a,
    body .media-container-element ol li a,
    body .section-content-element ol li a {
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: #eb6a3e;
    }

    .navigation-container .navigation-elements nav.main-navigation ul.level-0-container li.level-0 {
        margin-right: 25px;
    }

    .navigation-container .navigation-elements nav.main-navigation ul.level-0-container li.level-0.button-item {
        margin-right: 10px;
        margin-left: 15px;
    }
}

@media all and (max-width: 1219px), print {
    .offset {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .offset-top {
        padding-top: 25px;
    }

    .offset-bottom {
        padding-bottom: 25px;
    }

    .offset-top-single {
        padding-top: 10px;
    }

    .offset-bottom-single {
        padding-bottom: 10px;
    }

    .offset-top-none {
        padding-bottom: 0;
    }

    .offset-bottom-none {
        padding-bottom: 0;
    }

    h1,
    .headline-h1 {
        font-size: 3.2rem;
        line-height: 1.33;
        text-transform: none;
        margin-bottom: 0;
    }

    h2,
    .headline-h2 {
        font-size: 2.8rem;
        line-height: 1.33;
        text-transform: none;
        margin-bottom: 0;
    }

    h3,
    .headline-h3 {
        font-size: 2.4rem;
        line-height: 1.33;
        text-transform: none;
        margin-bottom: 0;
    }

    h4,
    .headline-h4,
    h5,
    .headline-h5,
    h6,
    .headline-h6 {
        font-size: 1.8rem;
        line-height: 1.33;
        text-transform: none;
        margin-bottom: 0;
    }

    body {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    body p a {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    body .content-container ul:not(.contact-list) li a,
    body .media-container-element ul:not(.contact-list) li a,
    body .section-content-element ul:not(.contact-list) li a,
    body .content-container ol li a,
    body .media-container-element ol li a,
    body .section-content-element ol li a {
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: #eb6a3e;
    }
}

/* border-radius
default: 4px - overrides:
top: 4px 4px 0 0
bottom: 0 0 4px 4px
left: 4px 0 0 4px
right: 0 4px 4px 0
*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .login-container {
        display: none;
    }

    .login-container.active {
        display: block;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .stage-element-container.layout-1 .stage-image-block figure img {
        height: auto;
    }
}

:root {
    --base__color-1: #1a2025;
    --base__color-2: #ffffff;
    --base__color-3: #eb6a3e;
    --base-icon-color: #1a2025;
}

.color-definition-0 {
    --base__color-1: #1a2025;
    --base__color-2: #ffffff;
    --base__color-3: #eb6a3e;
    --base-icon-color: #eb6a3e;
}

.color-definition-1 {
    --base__color-1: #ea9100;
    --base__color-2: #ffffff;
    --base__color-3: #ea9100;
    --base-icon-color: #ea9100;
}

.color-definition-2 {
    --base__color-1: #aebd23;
    --base__color-2: #ffffff;
    --base__color-3: #aebd23;
    --base-icon-color: #ffffff;
}

.color-definition-3 {
    --base__color-1: #f6f8fb;
    --base__color-2: #ffffff;
    --base__color-3: #f6f8fb;
    --base-icon-color: #f6f8fb;
}

.styleguide-page-container {
    margin-top: -129px;
    padding: 150px 0;
}

.styleguide-page-container .section-container.background-0 *,
.styleguide-page-container .section-container.background-1 *,
.styleguide-page-container .section-container.background-2 *,
.styleguide-page-container .section-container.background-4 *,
.styleguide-page-container .section-container.background-5 * {
    color: #ffffff;
}

.styleguide-color-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
}

.styleguide-color-row .styleguide-color-element {
    width: 40px;
    height: 40px;
    border-radius: 120px;
    margin-right: 15px;
}

.styleguide-color-row .styleguide-color-element:last-child {
    margin: 0;
}

.styleguide-color-row .styleguide-color-element.color-0 {
    background: #1a2025;
}

.styleguide-color-row .styleguide-color-element.color-1 {
    background: #ffffff;
    border: 1px solid rgba(26, 32, 37, 0.2);
}

.styleguide-color-row .styleguide-color-element.color-2 {
    background: #eb6a3e;
}

.styleguide-color-row .styleguide-color-element.color-3 {
    background: #ea9100;
}

.styleguide-color-row .styleguide-color-element.color-4 {
    background: #aebd23;
}

.styleguide-color-row .styleguide-color-element.color-5 {
    background: #f6f8fb;
    border: 1px solid rgba(26, 32, 37, 0.2);
}

.styleguide-color-row .styleguide-color-element.color-6 {
    background: #757575;
}

.styleguide-button-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    padding: 30px 0;
}

.styleguide-button-row > a {
    margin-right: 12px;
}

.styleguide-grid-view-container .styleguide-grid-box {
    background: rgba(235, 106, 62, 0.3);
    width: 100%;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 10px;
}
